import { AutomatitCarousel } from './automatit_carousel.m';

const heroCaro = {
	caro: document.querySelector('#home_hero_caro'),
	init: function() {

		const options = {
			element: this.caro,
			images: [
				{src: "/dist/images/backgrounds/img_homepage_hero.jpg", alt: ""},
				{src: "/dist/images/backgrounds/img_homepage_hero2.png", alt: ""},
				{src: "/dist/images/backgrounds/img_homepage_hero3.png", alt: ""},
				{src: "/dist/images/backgrounds/img_homepage_hero4.jpg", alt: ""},
			],
			imagesAsBackgrounds: true,
			showDots: true,
			useChevrons: false,
		};

		if(options.images.length === 1) {
			options.intervalTiming = false;
		}

		AutomatitCarousel(options);
	}
};

export const initHeroCaro = () => heroCaro.init();