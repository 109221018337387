import { contactSubmit } from "./contact.js";
import { initHeroCaro } from "./home.js";
import { navigationToggle } from "./header.js";

if (document.getElementById("contact_form")) {
	contactSubmit();
}

if (document.getElementById("maintenance_form")) {
	contactSubmit({
		elementSelector: "#maintenance_form",
		required: [
			"store",
			"address",
			"phone",
			"name",
			"email",
			"date",
			"comments",
		],
		submit: "maintenance_form_submit",
	});
}

if (document.querySelector("#home_hero_caro")) {
	initHeroCaro();
}
if (document.querySelector("#toggle_mobile_nav")) {
	navigationToggle();
}

const file_upload = document.getElementById("file-upload");
if (file_upload) {
	file_upload.addEventListener("change", () => {
		const file_upload_text = document.getElementById("file_upload_text");

		file_upload_text.value = file_upload.files[0].name;

		for (let i = 1; i < file_upload.files.length; i++) {
			file_upload_text.value += `, ${file_upload.files[i].name}`;
		}
	});
}
