export const navigationToggle = () => {

	document.getElementById('toggle_mobile_nav').addEventListener('click', function(e){
		const toggleBtn = e.currentTarget;
		const mobileNav = document.getElementById('mobile_nav_container');
        
		if(toggleBtn.classList.contains('active')) {
			toggleBtn.classList.remove('active');
			mobileNav.classList.remove('active');
		} else {
			toggleBtn.classList.add('active');
			mobileNav.classList.add('active');
		}
	});
};